
import {Link} from "react-router-dom";

export default function NavbarComponent(props){
    return (
        <div>
            <div className="gradient-moving-border">
                <ul className="nav justify-content-center nav-pills">
                    <li className="nav-item">
                        <Link to="/" className="nav-link hover-red h4">
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" className="nav-link hover-red h4">
                            About
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/blogs" className="nav-link hover-red h4">
                            Blogs
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/social-media" className="nav-link hover-red h4">
                            Social Media
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}