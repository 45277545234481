

import { useEffect, useState } from "react";

import {  useParams } from "react-router";

import { MdDateRange } from "react-icons/md";

import { giveDate } from "./blogs";

import axios from "axios";


export default function Read_blog(props){

    const [blog, setblog] = useState("loading");

    const { _id } = useParams();

    useEffect(() => {
        axios.post("https://ali-964.herokuapp.com/check/",
        {
            _id:_id
        }).then(r => {let new_r=JSON.parse(r.data);setblog(new_r);console.log(new_r.title);})
        .catch(e=>setblog(""))
    }, [_id])
    return (
        <div style={{height:"100%"}} 
        className="container-fluid text-center nice-back">
            {blog && blog !== "loading" ?
            <div>
                <br />
                <h4 className="display-4 text-dark">
                    {blog.title}
                </h4>
                <hr className="w-50 mx-auto text-dark" size="5" />
                <p className="fs-5 text-end w-50 mb-5 p-2">
                    <MdDateRange color="black" className="me-2" />
                    {giveDate(blog.date)}
                </p>
                <div className="container mb-5">
                    <div className="card border border-dark p-2 fs-4"
                    dangerouslySetInnerHTML={{__html:blog.content}}/>
                    <hr className="mb-5 border border-dark" size="5" />
                </div>
                <h4 className="display-6 text-center mb-5 border border-danger p-3">
                    Thank you for Reading
                </h4>
            </div> : 
            <div className="mx-auto">
                <div className="spinner-border text-primary mt-5">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            }
        </div>
    );
}

