// import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
// import './App.css';
import MainPage from './components/main';
import Blogs from "./components/blogs";
import CreateComp from "./components/create";
import AboutPage from "./components/about";
import NavbarComponent from "./components/navbarComp"
import Read_blog from "./components/read_blog";
import SocialMediaComponent from "./components/SocialMedia";


function App() {
  return (
      <div>
        <NavbarComponent />
        <Routes>
            <Route element={<MainPage/>} path="/" />
            <Route element={<AboutPage/>} path="/about" />
            <Route element={<SocialMediaComponent/>} path="/social-media" />
            <Route exact element={<Blogs/>} path="/blogs" />
            <Route  path="/blogs/:_id" element={<Read_blog/>} />
            <Route  path="/cr_eoiglvnelwngvpi" element={<CreateComp/>} />
        </Routes>
      </div>
  );
}

export default App;
