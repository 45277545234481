

import { useEffect, useState } from "react";

import { MdDateRange } from "react-icons/md";
import { Link } from "react-router-dom";

export const giveDate = i => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May" ,"Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let date = new Date(i)
    return date.getDay() + " " + months[date.getMonth()] + ", " + date.getFullYear()
}

export default function Blogs(props){

    const [list, setlist] = useState([]);

    useEffect(() => {
        fetch("https://ali-964.herokuapp.com/wnfeieufehf29458lg445oytg%25%25iup/")
        .then(r => r.json())
        .then(r => setlist(r))
    }, [])

    return (
        <div style={{height:"100vh"}} 
        className="container-fluid text-center nice-back">
           {list.length !== 0 ? 
           <div>
               <h4 className="display-4">
                    Blogs
                </h4>
                <hr className="w-50 mx-auto mb-5 text-dark" size="5" />
                <div className="container w-50">
                    {list.map(i => {
                        return (
                            <div className="card mx-auto border border-dark mt-3">
                                    <div className="row">
                                        <div className="col d-flex align-items-center mt-3">
                                            <img className="float-start ms-3"
                                            src={i.image} style={{height:"100", width:"100px"}} />
                                        </div>
                                        <div className="col d-flex text-start align-items-center">
                                            <Link to={"/blogs/"+i._id}
                                            className="fs-3 text-center">
                                                {i.title}
                                            </Link>
                                        </div>
                                    </div>
                                    <p className="fs-5 text-end me-3">
                                        <MdDateRange color="Fuchsia" className="me-2" />
                                        {giveDate(i.date)}
                                    </p>
                            </div>); }
                            )}
                </div>
           </div> : 
            <div className="mx-auto">
                <div className="spinner-border text-primary mt-5">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            }
        </div>
    );
}