import { FaDiscord } from "react-icons/fa";
import { AiFillYoutube, AiFillFacebook, AiFillGithub } from "react-icons/ai"
import { MdEmail } from "react-icons/md";

export default function SocialMediaComponent(props){
    return(
        <div className="bg-dark" style={{height: '100vh'}}>
            <br />
            <br />
            <br />
            <br />
            <div className="d-flex align-items-center justify-content-center container-fluid">
                <div className="text-left">
                    <p className="text-white display-6 text-center">
                        Links:
                        <hr className="text-white mt-0" size="4" />
                    </p>
                    <p className="text-light fs-3 fst-italic">
                        <AiFillGithub style={{color:"white"}} />
                        <a target="_blank"
                        href="https://github.com/adistar964" className="text-decoration-none ms-3 text-light">Github</a>
                    </p>
                    <p className="text-light fs-3 fst-italic">
                        <FaDiscord style={{color:"#5555dd"}} />
                        <a target="_blank"
                        href="https://discord.com/invite/RfXMuh7e?utm_source=Discord%20Widget&utm_medium=Connect" className="text-decoration-none ms-3 text-light">Discord</a>
                    </p>
                    <p className="text-light fs-3 fst-italic">
                        <AiFillYoutube className="text-danger" />
                        <a target="_blank"
                        href="https://www.youtube.com/channel/UCCQIkozPw9yhyOpI05Iye9w" className="text-decoration-none ms-3 text-light">Youtube</a>
                    </p>
                    <p className="text-light fs-3 fst-italic">
                        <AiFillFacebook className="text-primary" />
                        <a target="_blank"
                        href="https://www.facebook.com" className="text-decoration-none ms-3 text-light">Facebook</a>
                    </p>
                    <p className="text-light fs-3 fst-italic">
                        <MdEmail className="text-success" />
                        <a target="_blank"
                        href="mailto: adistar964@gmail.com" className="text-decoration-none ms-3 text-light">Gmail</a>
                    </p>
                </div>
            </div>
        </div>
    );
}