
import * as React from "react";
import { AiFillYoutube, AiFillFacebook } from "react-icons/ai"

export default function AboutPage(props){

    const [count, setCount] = React.useState("0")

     React.useEffect(()=>{
        (async()=> {
        let data = await fetch("https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UCCQIkozPw9yhyOpI05Iye9w&key=AIzaSyDHcJQTD_dFARr-YT7EL6KZr47J_bVTBns")
        data = await data.json()
        setCount(data["items"][0].statistics.subscriberCount);})()
    }, [])

    const Join_boards = props => {
        return(
        <div className="container-fluid mx-auto">
            <div className="container-fluid mt-5 text-center">
                <h4 className="text-center display-5 mb-4 mt-4">
                    Join me in Youtube!
                </h4>
                <div className="card mx-auto p-4 border border-danger mb-5" style={{width:"18rem"}}>
                    <div className="card-body text-center">
                        <AiFillYoutube className="text-danger display-4" />
                        <h4 className="card-title">
                            Live Youtube Subcriber Count
                        </h4>
                        <div className="p-3 mt-1 border border-danger fs-1 fst-italic">
                            {count}
                        </div>
                        <a target="_blank" 
                        href="https://www.youtube.com/channel/UCCQIkozPw9yhyOpI05Iye9w?sub_confirmation=1" 
                        className="mt-2 btn btn-outline-danger">
                            Subscribe
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <hr />
            </div>
            <div className="mb-5 align-items-center justify-content-center container-fluid text-center">
                <h4 className="text-center display-5 mb-1 mt-4">
                    Join me in discord!
                </h4>
                <iframe src="https://discord.com/widget?id=737935665240735786&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
        </div>
        );
    }

    return (
        <div>
            <div className="bg-dark">
                <br />
                <div className="align-items-center justify-content-center container-fluid text-center">
                    <h4 className="text-darkorange display-4 fw-bolder font-roboto">
                        <br />
                        <span className="fst-italic"> Hello! </span>
                        This is Ali
                    </h4>
                    <br />
            
                    <br /><br />
                </div>
            </div>
            <Join_boards />
        </div>
    )
}