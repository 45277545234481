

export default function About(props){
    return (
        <div className="container-fluid">
            <div className="card container border border-dark p-3 mt-5">
                <h4 className="display-6 fw-bold text-center">
                    About me
                </h4>
                <hr className="w-50 mx-auto" size="5" />
                <div className="d-flex justify-content-center">
                    <p className="mt-3 fs-5 text-start">
                        * High-School Student <br />
                        * Programmer <br />
                        * Name: Mohammed Abdullah Amaan <br />
                        * Social-Name: Ali <br />
                        * Knows Java, Python, JavaScript, Golang, C, C++ <br />
                        * Better(mastered) in Python and Javascript  <br />
                        * Runs a Youtube Channel  <br />
                    </p>
                </div>
            </div>
            <div className="card container border border-dark p-3 mt-5 mb-5">
                <h4 className="display-6 fw-bold text-center">
                    About this website
                </h4>
                <hr className="w-50 mx-auto" size="5" />
                <div className="d-flex justify-content-center">
                    <p className="mt-3 fs-5 text-center">
                        This website is a personal website, which includes information about me,my projects, my blogs and other things. This Website was made so that people could get a better idea of myself, and other purpose of this site is to handle my personal-written blogs. 
                    </p>
                </div>
            </div>
        </div>
    )
}