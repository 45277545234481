
import { Editor } from "react-draft-wysiwyg";
import { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from "axios";

export default function CreateComp(props){

    const [content, setcontent] = useState(EditorState.createEmpty())
    const [title, set_title] = useState("")
    const [image, set_image] = useState("")

    const changecontent = e=>setcontent(e)

    const run_post_req = () => {
        let body_of_blog = draftjsToHtml(convertToRaw(content.getCurrentContent()))
        axios.post("https://ali-964.herokuapp.com/cr_sdsdsdsbvolw4h%%er7557558t/", {
            title:title, body:body_of_blog,
            img:image, pass:"np3fv@4!08v@04#2g&0b%4v%gv"
        }).then(r => {
            console.log(r)
            alert("done")
        })

    }

    return (
        <div className="mt-5 container">
            <p className="display-6 text-center">
                Create Your Blog
            </p>
            <input type="text" placeholder="title..."
            onChange={e=>set_title(e.target.value)} 
            className="form-control mb-4"/>
            <input type="text" placeholder="image-url..."
            onChange={e=>set_image(e.target.value)} 
            className="form-control mb-4"/>
            <div className="border border-dark p-3">
                <Editor editorState={content}
                onEditorStateChange={changecontent} 
                placeholder="content...." />
            </div>
            <div className="text-center">
                <button onClick={run_post_req} 
                className="btn btn-primary mt-3">
                    Create
                </button>
            </div>
        </div>
    );
}